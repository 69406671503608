import React from "react";
import Body from '../components/Body/Body';
import PageLayout from '../components/PageLayout/PageLayout';

const title = "Klachtenregeling";
const headerText = "<p>Wanneer u een klacht heeft zullen we eerst proberen om hier samen uit te komen. Wanneer dit niet\n" +
    "afdoende blijkt te zijn dan kan er gebruik gemaakt worden van de klachtenprocedure van de FVB\n" +
    "(Federatie Vaktherapeutische Beroepen), SKJ (Stichting Kwaliteitsregister Jeugd) of bij\n" +
    "Klachtenportaalzorg.</p>";
const header = null;
const text = null;
const img = null;
const header2 = '';
const text2 = '';
const img2 = '';
const header3 = null;
const text3 = null;
const img3 = null;

const Klachten = () => {
    return (
        <>
            <Body>
                <PageLayout title={title} headerText={headerText} header={header} text={text} img={img}
                            header2={header2} text2={text2} img2={img2}
                            header3={header3} text3={text3} img3={img3}/>
            </Body>
        </>
    );
}

export default Klachten;
