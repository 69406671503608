import React from "react";
import Body from '../components/Body/Body';
import PageLayout from '../components/PageLayout/PageLayout';

const title = "Contact";
const headerText = "Elsemieke de Vos<br>Rijksstraatweg 61<br>3921 AB Elst (Utrecht)<br>06 18 28 56 14<br>" +
    "<a href='mailto:speltherapiepraktijk.deVerwondering@outook.com'>speltherapiepraktijk.deVerwondering@outook.com</a>";
const header = null;
const text = null;
const img = null;
const header2 = '';
const text2 = 'Voor informatie over de speltherapie of het maken van een afspraak kunt u mij bereiken via het\n' +
    'bovenstaande telefoonnummer of emailadres.';
const img2 = '';
const header3 = null;
const text3 = null;
const img3 = null;

const Contact = () => {
    return (
        <>
            <Body>
                <PageLayout title={title} headerText={headerText} header={header} text={text} img={img}
                            header2={header2} text2={text2} img2={img2}
                            header3={header3} text3={text3} img3={img3}/>
            </Body>
        </>
    );
}

export default Contact;
