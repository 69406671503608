import React from "react";
import Body from '../components/Body/Body';
import PageLayout from '../components/PageLayout/PageLayout';

const title = "Over mij";
const headerText = "Al van jongs af aan zijn kinderen een belangrijk onderdeel van mijn leven. Ik heb een rustige\n" +
    "uitstraling en kan goed aansluiten bij kinderen waardoor zij zich snel op hun gemak voelen.\n" +
    "Verder ben ik iemand die denkt in mogelijkheden, ik ben een geduldig persoon en blijf positief.\n" +
    "Als speltherapeut onderscheid ik me op meerdere gebieden. Ik ben gespecialiseerd in de volgende\n" +
    "richtingen: jeugdhulp, GGZ en dan voornamelijk ASS in combinatie comorbiditeit.\n" +
    "De kracht van mijn begeleiding ligt vooral in de rust die ik uitstraal, mijn geduld en congruentie.\n" +
    "Deze combinatie zorgt ervoor dat kinderen zich snel op hun gemak voelen.\n" +
    "Door mijn fijngevoeligheid kan ik goed aansluiten bij wat de kinderen op dat moment nodig hebben\n" +
    "in de spelsessie.\n" +
    "Ik ben stressbestendig. Doordat ik jaren als groepsleider heb gewerkt in een crisisopvang jonge jeugd\n" +
    "en hierna als sociotherapeut op een gesloten crisisafdeling ben ik niet snel onder de indruk van\n" +
    "externaliserend gedrag van kinderen.\n" +
    "Als ik het kort moet samenvatten dan zal ik mij onderscheiden in het aanbieden van speltherapie aan\n" +
    "kinderen met autisme, externaliserende problematiek, angst, hooggevoeligheid en\n" +
    "hechtingsproblematiek.";
const header = "Wat is de betekenis van de naam 'De Verwondering?'";
const text = "Kinderen kunnen zich verwonderen over hele kleine dingen. Er zijn momenten in het leven van\n" +
    "kinderen dat zij dit verwonderen zijn kwijtgeraakt. Soms is het voor ouders lastig om zich te\n" +
    "verwonderen over hun kind als er veel aan de hand is. Als speltherapeut verwonder ik mij over de\n" +
    "veerkracht die kinderen bezitten. Het is mijn wens dat kinderen en ouders het verwonderen weer\n" +
    "terug vinden door speltherapie.";
const img = 'image/overmij.jpg';
const header2 = "Afgeronde diploma’s";
const text2 = ["MAVO", "MDGO SPW", "HBO SPH", "Master Speltherapie"];
const img2 = null;
const header3 = "Afgeronde trainingen/cursussen";
const text3 = ["Persoonlijkheidsstoornissen", "Praktische psychiatrie en agressieregulatie", "Competentietraining", "Complexe hulpverlening", "Agressietraining", "Basiscursus begeleiden mensen met ASS", "Aanvullend comorbiditeit bij mensen met ASS", "Gezinsgesprekken", "Overprikkeling", "Gentle teaching"];
const img3 = 'image/overmij2.jpg';

const About = () => {
    return (
        <>
            <Body>
                <PageLayout title={title} headerText={headerText} header={header} text={text} img={img}
                            header2={header2} text2={text2} img2={img2}
                            header3={header3} text3={text3} img3={img3}/>
            </Body>
        </>
    );
}

export default About;
