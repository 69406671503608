import React from "react";
import Body from "../components/Body/Body";

function Home() {
    return (
        <Body>
            <div className="background">
                <div className="wrapper">
                    <h1 className="homeTitle">Speltherapie</h1>
                </div>
            </div>
        </Body>
    );
}

export default Home;