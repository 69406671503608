import React from "react";
import "./PageLayout.css";
import parse from 'html-react-parser'

const PageLayout = (props) => {
    return (
        <div className="container-fluid">
            <div className="content">
                <div className="head row row-cols-1">
                    <div className="title mt-3 ml-5 col">
                        {props.title}
                    </div>
                    <div className="text col ml-5">
                        {props.img !== null ? (<img src={props.img} className="float-right img"/>) : ("")}
                        <p>{parse(props.headerText)}</p>
                    </div>
                    <div className="title mt-3 ml-5 col">
                        {props.header}
                    </div>
                    <div className="text col ml-5">
                        {props.img1 !== null ? (<img src={props.img1} className="float-left img"/>) : ("")}
                        {props.text !== null ? (<p>{props.text}</p>) : ("")}

                    </div>
                </div>
                <div className="body body-content row row-cols-1">
                    {props.header2 !== null ? (
                        <>
                            <div className="title mt-3 ml-5 col">
                                {props.header2}
                            </div>
                            <div className="text col ml-5">
                                {props.img2 !== null ? (<img src={props.img2} className="float-left img"/>) : ("")}
                                <p>{Array.isArray(props.text2) ?
                                    (<ul>
                                        {props.text2.map((item, index)=> <li key={index}>{item}</li>)}
                                    </ul>)
                                    : parse(props.text2)}</p>
                            </div>
                        </>
                    ) : ("")}

                    {props.header3 !== null ? (
                        <>
                            <div className="title mt-3 ml-5 col">
                                {props.header3}
                            </div>
                            <div className="text col ml-5">
                                {props.img3 !== null ? (<img src={props.img3} className="float-left img"/>) : ("")}
                                <p>{Array.isArray(props.text3) ?
                                    (<ul>
                                        {props.text3.map((item, index)=> <li key={index}>{item}</li>)}
                                    </ul>)
                                    : props.text3}</p>
                            </div>
                        </>
                    ) : ("")}
                </div>
                <div className="footer">
                    <div className="footer-content">
                        <div className="left">
                            <strong>Contact</strong>
                            <p>info@speltherapiepraktijkdeverwondering.nl</p>
                            <p>06 18 28 56 14</p>
                        </div>
                        <div className="right">
                            <strong>Adres</strong>
                            <p>Rijksstraatweg 61 3921 AB Elst (Utrecht)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageLayout;
