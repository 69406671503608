import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./page/Home";
import About from "./page/About";
import Spelkamer from "./page/Spelkamer";
import Werkwijze from "./page/Werkwijze";
import Privacy from "./page/Privacy";
import Klachten from "./page/Klachten";
import Contact from "./page/Contact";
import Informatie from "./page/Informatie";
import Tarieven from "./page/Tarieven";

function App() {
    return (
        <>
            <Router>
                <Navbar/>
                <Routes>
                    <Route path='/' exact element={<Home />}/>
                    <Route path='/overMij' exact element={<About />}/>
                    <Route path='/spelkamer' exact element={<Spelkamer />}/>
                    <Route path='/werkwijze' exact element={<Werkwijze />}/>
                    <Route path='/privacy' exact element={<Privacy />}/>
                    <Route path='/klachten' exact element={<Klachten />}/>
                    <Route path='/contact' exact element={<Contact />}/>
                    <Route path='/informatie' exact element={<Informatie />}/>
                    <Route path='/tarieven' exact element={<Tarieven />}/>
                </Routes>
            </Router>
        </>
    );
}

export default App;
