import React from "react";
import Body from '../components/Body/Body';
import PageLayout from '../components/PageLayout/PageLayout';

const title = "Privacy";
const headerText = "Voor een goede behandeling is het noodzakelijk dat ik, als uw behandelende therapeut, een dossier"+
"aanleg. Dit is ook een wettelijke plicht opgelegd door de WGBO. In het dossier van het kind staat het"+
"behandelplan en na elke spelsessie schrijf ik een rapportage waarin ik beschrijf welke thema’s er zijn"+
"uitgespeeld, de interventies die zijn ingezet en mogelijke hypotheses die getrokken kunnen worden.<br><br>"+
    "Ook worden in het dossier de verslagen van de oudergesprekken opgenomen. Eventuele gegevens"+
"die voor de behandeling van het kind noodzakelijk zijn (bijvoorbeeld een diagnostisch onderzoek) en"+
"die ik, na uw expliciete toestemming, heb opgevraagd bij een andere zorgverlener.<br><br>"+
"Ik doe mijn best om uw privacy te waarborgen. Dit betekent onder meer dat ik:"+
"<ul><li>zorgvuldig omga met de persoonlijke en medische gegevens van uw kind;</li>" +
    "<li>ervoor zorg dat onbevoegden geen toegang hebben tot uw gegevens.</li>" +
    "<li>Als behandelend therapeut van uw kind heb ik als enige toegang tot de gegevens in het\n" +
    "dossier.</li>" +
    "<li>Ik heb een wettelijke geheimhoudingsplicht (beroepsgeheim).</li></ul>" +
    "De gegevens uit het dossier kunnen ook nog voor de volgende doelen gebruikt worden:" +
    "<ul><li>Om andere zorgverleners te informeren, bijvoorbeeld als de therapie is afgerond of bij een\n" +
    "verwijzing naar een andere behandelaar. Dit gebeurt alleen met uw expliciete toestemming.</li>" +
    "<li>Voor het gebruik voor waarneming, tijdens mijn afwezigheid.</li>" +
    "<li>Voor het geanonimiseerde gebruik tijdens intercollegiale toetsing (supervisie/intervisie).</li>" +
    "<li>Een klein deel van de gegevens uit het dossier wordt gebruikt voor de financiële\n" +
    "administratie, zodat ik of mijn administrateur, een factuur kan opstellen.</li></ul><br>" +
    "Als ik vanwege een andere reden gebruik wil maken van uw gegevens, dan zal ik u eerst informeren\n" +
    "en expliciet uw toestemming vragen.\n" +
    "Deze gegevens in het cliëntendossier blijven zoals in de wet op de behandelovereenkomst wordt\n" +
    "vereist 15 jaar bewaard.";
const header = null;
const text = null;
const img = null;
const header2 = 'Privacy op de zorgnota';
const text2 = "Op de zorgnota die u ontvangt staan de gegevens die door de zorgverzekeraar, gemeente of Sociale\n" +
    "Verzekeringsbank gevraagd worden, zodat u deze nota kunt declareren." +
    "<ul><li>Naam, adres en woonplaats</li>" +
    "<li>Geboortedatum</li>" +
    "<li>BSN of SVB-klantnummer (indien wettelijk verplicht)</li>" +
    "<li>De datum van de behandeling</li>" +
    "<li>Een korte omschrijving van de behandeling zoals voorgeschreven door de zorgverzekeraar,\n" +
    "gemeente of Sociale Verzekeringsbank en indien van toepassing de bijbehorende prestatiecode.\n" +
    "Bijvoorbeeld ‘behandeling vaktherapie’, intake of evaluatiegesprek etc.</li>" +
    "<li>De kosten van het consult</li></ul>";
const img2 = null;
const header3 = null;
const text3 = null;
const img3 = null;

const Privacy = () => {
    return (
        <>
            <Body>
                <PageLayout title={title} headerText={headerText} header={header} text={text} img={img}
                            header2={header2} text2={text2} img2={img2}
                            header3={header3} text3={text3} img3={img3}/>
            </Body>
        </>
    );
}

export default Privacy;
