import React from "react";
import Body from '../components/Body/Body';
import PageLayout from '../components/PageLayout/PageLayout';

const title = "Aanmelden";
const headerText = "Een kind kan aangemeld worden via een hulpverlenersorganisatie, Centrum Jeugd en Gezin, school of de ouders zelf. \n";
const header = "Kennismaking";
const text = "Vooraf aan het kennismakingsgesprek wordt er een vragenlijst gemaild om alvast wat achtergrond informatie te hebben over het kind. Het gesprek zal 45 minuten tot 60 minuten duren. \n";
const img = 'image/werkwijze.jpg';
const header2 = "Observatieperiode";
const text2 = "Als er na de kennismaking van beide kanten vertrouwen is om speltherapie in te zetten wordt er gestart, met een observatieperiode van drie spelsessies. In de observatieperiode wordt gekeken of het kind tot spelen komt, welke thema’s het uitspeelt en aan welke mogelijke doelen er gewerkt kunnen worden tijdens de behandeling. Een spelsessie duurt 45 minuten. ";
const img2 = null;
const header3 = "Oudergesprek";
const text3 = "Na de observatieperiode (drie spelsessies) volgt een oudergesprek en bespreken we de doelen voor het behandelplan.\n" +
    "Na elke vijf spelsessies volgt een oudergesprek waarin we met elkaar bespreken, hoe het gaat met jullie kind, wat er binnen speltherapie wordt gezien en waar de komende periode aan wordt gewerkt. Een oudergesprek duurt 45 tot 60  minuten. ";
const img3 = null;

const Werkwijze = () => {
    return (
        <>
            <Body>
                <PageLayout title={title} headerText={headerText} header={header} text={text} img={img}
                            header2={header2} text2={text2} img2={img2}
                            header3={header3} text3={text3} img3={img3}/>
            </Body>
        </>
    );
}

export default Werkwijze;
