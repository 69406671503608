import React from "react";
import Body from '../components/Body/Body';
import PageLayout from '../components/PageLayout/PageLayout';

const title = "Tarieven";
const headerText = "<ul>" +
    "<li>Kennismaking/intakegesprek is gratis</li>" +
    "<li>Spelsessie kost 75 euro</li>" +
    "<li>Oudergesprek kost 75 euro</li>" +
    "<li>Multidisciplinair overleg (bijvoorbeeld een overleg met de ouders en school, andere" +
    "hulpverleningsorganisaties) kost 80 euro.</li>" +
    "</ul>";
const header = null;
const text = '';
const img = null;
const header2 = '';
const text2 = 'Een groot aantal zorgverzekeraars vergoedt speltherapie (gedeeltelijk) via de aanvullende\n' +
    'verzekering. Doordat ik als speltherapeut beschik over de beroepsregistratie van de FVB/NVVS zijn er\n' +
    'meer vergoedingsmogelijkheden. Hierbij kunt u denken aan vergoeding vanuit ZIN (zorg in natura)\n' +
    'die verkregen wordt als de gemeente een beschikking afgeeft voor speltherapie. Ook kun je denken\n' +
    'aan de vergoeding vanuit PGB. ';
const img2 = null;
const header3 = null;
const text3 = null;
const img3 = null;

const Spelkamer = () => {
    return (
        <>
            <Body>
                <PageLayout title={title} headerText={headerText} header={header} text={text} img={img}
                            header2={header2} text2={text2} img2={img2}
                            header3={header3} text3={text3} img3={img3}/>
            </Body>
        </>
    );
}

export default Spelkamer;
