import React from "react";
import Body from '../components/Body/Body';
import PageLayout from '../components/PageLayout/PageLayout';

const title = "De spelkamer";
const headerText = "<p>De vloer in de spelkamer is van kurk, dit zorgt voor een rustige uitstraling.\n" +
    "In de spelkamer is er een groot aanbod aan gevarieerd spel.\n" +
    "Er is een zand/watertafel, Playmobil, auto’s, poppen, knutselspullen, een keukentje en nog veel meer\n" +
    "leuke dingen om mee te spelen.</p>";
const header = null;
const text = null;
const img = null;
const header2 = '';
const text2 = '';
const img2 = 'image/spelkamer.jpg';
const header3 = null;
const text3 = null;
const img3 = null;

const Spelkamer = () => {
    return (
        <>
            <Body>
                <PageLayout title={title} headerText={headerText} header={header} text={text} img={img}
                            header2={header2} text2={text2} img2={img2}
                            header3={header3} text3={text3} img3={img3}/>
            </Body>
        </>
    );
}

export default Spelkamer;
