import React from "react";
import Body from '../components/Body/Body';
import PageLayout from '../components/PageLayout/PageLayout';

const title = "Jij komt naar de spelkamer";
const headerText = "<p>In de spelkamer krijg je speltherapie. Waarschijnlijk vraag je je af wat dat is. Ik ga het proberen uit te\n" +
    "leggen. Bij speltherapie mag je elke week drie kwartier in de spelkamer komen spelen. Je mag zelf\n" +
    "kiezen waar je mee wilt spelen. Je kunt met de Playmobil, met de zandtafel, met het piratenschip en met de poppen spelen. Je kunt tekenen, knutselen en er is nog veel meer. Alles\n" +
    "mag en is goed. Jij mag kiezen en in de spelkamer hoef je nooit zelf op te ruimen</p>";
const header = 'Waarom willen je papa en/of mama dat je naar speltherapie gaat?';
const text = 'Er zijn heel veel verschillende redenen waarvoor kinderen naar speltherapie komen. Misschien heb je\n' +
    'iets vervelends mee gemaakt, ben je bang, boos of verdrietig. Misschien vind je het lastig om er over\n' +
    'te praten maar je papa en mama hebben gezien dat je niet lekker in je vel zit. Door te spelen kun je je\n' +
    'weer fijn gaan voelen en kunnen dingen waar je last van hebt verdwijnen.';
const img = 'image/informatie.jpg';
const header2 = 'Even vertellen wie ik ben!';
const text2 = 'Ik ben Elsemieke en speltherapeut. Therapeut betekent helper. Ik vind het leuk om met kinderen te\n' +
    'spelen en dus ook met jou. Als je wilt speel ik met je mee en als je dat niet wil is dat ook helemaal\n' +
    'goed. Als je een tijdje naar speltherapie bent geweest ga je je beter voelen. Je bent niet meer zo\n' +
    'bang, boos of verdrietig. Want spelen helpt en het is fijn als iemand je begrijpt. We nemen afscheid\n' +
    'van elkaar als het weer beter met je gaat.';
const img2 = 'image/informatie2.jpg';
const header3 = null;
const text3 = null;
const img3 = null;

const Informatie = () => {
    return (
        <>
            <Body>
                <PageLayout title={title} headerText={headerText} header={header} text={text} img={img}
                            header2={header2} text2={text2} img2={img2}
                            header3={header3} text3={text3} img3={img3}/>
            </Body>
        </>
    );
}

export default Informatie;
